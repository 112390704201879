import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  OutlinedInput
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import useSingleToast from '../hooks/useSingleToast'
import useUser from '../hooks/useUser'
import IconButton from '@material-ui/core/IconButton'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import usePdfGenerator from '../hooks/usePdfGenerator'
import GetAppIcon from '@material-ui/icons/GetApp'
import Grid from '@material-ui/core/Grid'
import useGameConfig from '../hooks/useGameConfig'

function CopyCodeModal ({ open, setOpen }) {
  const { addSingleToast } = useSingleToast()
  const { user } = useUser()
  const { generateAndDownloadPdf } = usePdfGenerator()
  const { texts: { notificationTexts } } = useGameConfig()

  const handleClose = () => {
    setOpen(false)
  }

  const handleFocus = event => {
    if (event.target.select) {
      event.target.select()
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>{notificationTexts.yourCode}</DialogTitle>
        <DialogContent>
          <OutlinedInput
            label="Dein Zugangscode"
            notched={false}
            defaultValue={user?.code}
            readOnly={true}
            variant="outlined"
            onFocus={handleFocus}
            onBlur={handleFocus}
            onClick={handleFocus}
            fullWidth
            endAdornment={
              <InputAdornment position="end">
                <CopyToClipboard text={user?.code} onCopy={() => {
                  addSingleToast(
                    <div>
                      <p>{notificationTexts.codeCopied}</p>
                    </div>,
                    { appearance: 'success' })
                }}>
                  <IconButton>
                    <FileCopyIcon/>
                  </IconButton>
                </CopyToClipboard>
              </InputAdornment>
            }
          />
        </DialogContent>
        <DialogActions>
          <Grid container style={{ margin: '0 15px 15px 15px' }}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<GetAppIcon/>}
                onClick={() => {
                  let text = notificationTexts.pdfText
                    .replace('${nickName}', user?.nickName)
                    .replace('${code}', user?.code)
                  generateAndDownloadPdf(text, 'sdg-zugangscode.pdf')
                }}>
                {notificationTexts.saveAsPdf}
              </Button>
            </Grid>
            <Grid item xs={12} className="space-top-20">
              <Button
                variant="contained"
                onClick={handleClose}
                style={{ float: "right", marginTop: 20 }}
              >
                {notificationTexts.close}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CopyCodeModal