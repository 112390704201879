import React, { useState } from 'react'
import SyncProblemIcon from '@material-ui/icons/SyncProblem'
import IconButton from '@material-ui/core/IconButton'
import useNetwork from '../../hooks/useNetwork'
import useUser from '../../hooks/useUser'
import useIsClient from '../../hooks/useIsClient'
import SyncIcon from '@material-ui/icons/Sync'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import NotificationModal from '../NotificationModal'
import { Badge } from '@material-ui/core'
import useTeamInviteNotifier from '../../hooks/useTeamInviteNotifier'
import LogoutButton from './LogoutButton'

const Status = () => {
  const isOnline = useNetwork()
  const { isClient } = useIsClient()
  const { signedIn, user, syncStatus } = useUser()
  const [notificationModalOpen, setNotificationModalOpen] = useState(false)
  const { notifications } = useTeamInviteNotifier()

  if (!isClient || !signedIn) {
    return null
  }

  return (
    <>
      <div>{user.nickName}</div>
      <div className="app-bar-status">
        <NotificationModal open={notificationModalOpen} setOpen={setNotificationModalOpen}/>
        <IconButton
          edge="end"
          onClick={() => setNotificationModalOpen(true)}
        >
          <Badge badgeContent={notifications.length} color="error">
            <NotificationsNoneIcon/>
          </Badge>
        </IconButton>
        {!isOnline && (
          <IconButton
            edge="end"
            onClick={() => window.location.reload()}
          >
            <SyncProblemIcon style={{ color: 'yellow' }}/>
          </IconButton>
        )}
        {syncStatus === 'notSynced' && (
          <IconButton
            edge="end"
            onClick={() => window.location.reload()}
          >
            <SyncIcon style={{ color: 'lightgreen' }} className="rotate"/>
          </IconButton>
        )}
        {syncStatus !== 'notSynced' && <LogoutButton/>}
      </div>
    </>
  )
}

export default Status
