import React from 'react'
import AppBar from './AppBar'
import Container from '@material-ui/core/Container'
import TopLayout from './top-layout'
import '@fontsource/open-sans' // Defaults to weight 400 with all styles included.
import Toolbar from '@material-ui/core/Toolbar'
import Fab from '@material-ui/core/Fab'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import '../styles/global.scss'
import ScrollTop from './scroll-top.js'
/*import useIsStandalone from '../hooks/useIsStandalone'
import useCookieConsent from '../hooks/useCookieConsent'*/

export default function CenteredGrid ({ children, pageName, pageSubName }) {
/*  const { isStandalone } = useIsStandalone()
  const cookieConsent = useCookieConsent()*/

  return (
    <TopLayout className="top-layout">
      {/*!isStandalone() && cookieConsent*/}
      <AppBar/>
      <Toolbar id="back-to-top-anchor"/>
      <Container maxWidth="lg" className="main-container">{children}</Container>
      <ScrollTop>
        <Fab color="primary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon/>
        </Fab>
      </ScrollTop>
    </TopLayout>
  )
}
