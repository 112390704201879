import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button'
import useIsClient from './useIsClient'
import IphoneShare from '../../static/images/iphone_share.inline.svg'
export const useIosPwaInstaller = (setShowIosInstallDialog) => {
  const { isClient } = useIsClient()



  // Detects if device is on iOS
  const isIos = () => {
    if (!isClient) return null

    const userAgent = window.navigator.userAgent.toLowerCase()
    return /iphone|ipad|ipod/.test(userAgent)
  }

  // Detects if device is in standalone mode
  const isInStandaloneMode = () => {
    if (!isClient) return null

    return true
    //TODO: Does not work! return ('standalone' in window.navigator) && (window.navigator.standalone)
}

  const handleCloseIosInstallDialog = () => {
    setShowIosInstallDialog(false)
  }

  const renderIosInstallDialog = (open) => {
    return (
      <Dialog
        open={open}
        onClose={handleCloseIosInstallDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Wirkel auf dem Homescreen ablegen</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ol>
              <li>
                <div>Tippe auf das Teilen (<IphoneShare style={{width: '3em', height: '2em'}} />) Symbol in deinem Browser</div>
              </li>
              <li>
                Zum Home-Bildschirm wählen
              </li>
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseIosInstallDialog} color="primary" autoFocus>
            Schießen
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return { isIos, isInStandaloneMode, renderIosInstallDialog }
}