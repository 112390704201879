import React, { useEffect, useState } from 'react'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import useUser from '../../hooks/useUser'
import { usePWAInstall } from 'react-use-pwa-install'
import { useIosPwaInstaller } from '../../hooks/useIosPwaInstaller'
import CopyCodeModal from '../CopyCodeModal'
import ConfigurableMenuBottom from './ConfigurableMenuBottom'
import ConfigurableMenuTop from './ConfigurableMenuTop'
import useSingleToast from '../../hooks/useSingleToast'
import useGameConfig from '../../hooks/useGameConfig'
import useExternalLinkHandler from '../../hooks/useExternalLinkHandler'

function Menu ({ toggleDrawer, state }) {
  const { texts: { notificationTexts } } = useGameConfig()
  const { menuConfig: { hamburgerMenu } } = useGameConfig()
  const { addSingleToast } = useSingleToast()
  const { signedIn } = useUser()
  const install = usePWAInstall()
  const [showIosInstallDialog, setShowIosInstallDialog] = useState(false)
  const { isIos, isInStandaloneMode, renderIosInstallDialog } = useIosPwaInstaller(setShowIosInstallDialog)
  const [copyCodeDialogOpen, setCopyCodeDialogOpen] = useState(false)
  const [isFirefox, setFirefox] = useState(false)
  const { showExternalLinkConfirmation } = useExternalLinkHandler()


  useEffect(() => {
    setFirefox(typeof InstallTrigger !== 'undefined')
  }, [])


  const anchor = 'left'

  function handlePwaIosInstall () {
    setShowIosInstallDialog(true)
  }

  function isIosInstallable () {
    return isIos() && !isInStandaloneMode()
  }

  const list = anchor => (
    <div
      className="top left app-bar-list"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="">

        {isIos()
          ? <ListItem
            button key="download-ios"
            onClick={handlePwaIosInstall}
            disabled={isIosInstallable()}
          >
            <ListItemIcon>
              <InboxIcon/>
            </ListItemIcon>
            <ListItemText primary={hamburgerMenu.addToDesktop}/>
          </ListItem>
          : isFirefox ?
            <ListItem
              button key="download-android"
              onClick={() =>
                addSingleToast(
                  <div>
                    <p>{notificationTexts.browserNotSupported}</p>
                  </div>,
                  { appearance: 'warning' })
              }
              style={{ color: '#c6c2c2' }}
            >
              <ListItemIcon>
                <InboxIcon style={{ color: '#c6c2c2' }}/>
              </ListItemIcon>
              <ListItemText primary="Als App herunterladen"/>
            </ListItem>
            :
            <ListItem
              button key="download-android"
              onClick={install}
              disabled={!install}
            >
              <ListItemIcon>
                <InboxIcon/>
              </ListItemIcon>
              <ListItemText primary="Als App herunterladen"/>
            </ListItem>
        }
        <Divider/>

        <ConfigurableMenuTop/>

        {signedIn &&
        <ListItem button key="zugangscode" onClick={() => setCopyCodeDialogOpen(true)}>
          <ListItemIcon>
            <VpnKeyIcon/>
          </ListItemIcon>
          <ListItemText primary="Mein Zugangscode"/>
        </ListItem>
        }
      </List>
      <Divider/>
      <ConfigurableMenuBottom/>
      <List>
        <ListItem button>
          <a
            href={'https://anu-hessen.de/impressum/'}
            style={{ paddingLeft: '1em', color: 'rgba(0, 0, 0, 0.54)' }}
            onClick={showExternalLinkConfirmation}
            target="_blank"
          >
            <ListItemText primary="Impressum"/>
          </a>
        </ListItem>
      </List>
    </div>
  )

  return (
    <>
      {renderIosInstallDialog(showIosInstallDialog)}
      <CopyCodeModal open={copyCodeDialogOpen} setOpen={setCopyCodeDialogOpen}/>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        className="hamburger-menu-drawer"
        onClose={toggleDrawer(anchor, false)}
      >
        {list(anchor)}
      </Drawer>
    </>
  )
}

export default Menu