import React from 'react'
import { jsPDF } from 'jspdf'
import useIsClient from './useIsClient'

function usePdfGenerator () {
  const { isClient } = useIsClient()


  function generateAndDownloadPdf (text, filename) {
    if (!isClient) return null

    const doc = new jsPDF()

    doc.text(text, 10, 20)
    doc.save(filename)
  }

  return { generateAndDownloadPdf }
}

export default usePdfGenerator