import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import { Icon } from '@material-ui/core'
import useGameConfig from '../../hooks/useGameConfig'
import { Link } from 'gatsby'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import useUser from '../../hooks/useUser'

function ConfigurableMenuTop () {
  const { menuConfig: { hamburgerMenu } } = useGameConfig()
  const { signedIn } = useUser()

  return (
    <>
      {hamburgerMenu.menuItemsPublic.flatMap(menuItem => {
        if (signedIn && menuItem.menuItemHideFromSignedin) {
          return []
        }

        return [(
          <ListItem
            button
            key={menuItem.menuItemUrl}
            component={Link}
            to={menuItem.menuItemUrl}
          >
            <ListItemIcon>
              <Icon>{menuItem.menuItemIcon}</Icon>
            </ListItemIcon>
            <ListItemText primary={menuItem.menuItemLabel}/>
          </ListItem>
        )]
      })}

      <Divider/>

      {signedIn && (
        <>
          <Divider/>
          {hamburgerMenu.menuItemsSignedIn.map(menuItem => {
            return (
              <ListItem
                button
                key={menuItem.menuItemUrl}
                component={Link}
                to={menuItem.menuItemUrl}
              >
                <ListItemIcon>
                  <Icon>{menuItem.menuItemIcon}</Icon>
                </ListItemIcon>
                <ListItemText primary={menuItem.menuItemLabel}/>
              </ListItem>
            )
          })}

        </>
      )}
    </>
  )
}

export default ConfigurableMenuTop