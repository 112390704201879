import React from 'react'
import { Button } from '@material-ui/core'
import { navigate } from 'gatsby'
import useUser from '../../hooks/useUser'
import IconButton from '@material-ui/core/IconButton'
import { ExitToApp } from '@material-ui/icons'
import useSingleToast from '../../hooks/useSingleToast'
import useGameConfig from '../../hooks/useGameConfig'

function LogoutButton () {
  const { logout } = useUser()
  const { addSingleToast, removeThisToast } = useSingleToast()
  const { texts: { notificationTexts } } = useGameConfig()

  return (
    <IconButton
      edge="end"
      onClick={() => {
        addSingleToast(
          <div className="logout-toast">
            <p>{notificationTexts.areYouSureToLogOut}</p>
            <div className="logout-toast-buttons">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  logout(() => navigate(`/`))
                  removeThisToast()
                }}
              >
                Ja!
              </Button>

              <Button
                variant="contained"
                onClick={() => removeThisToast()}
              >
                Schließen
              </Button>
            </div>
          </div>,
          { appearance: 'warning' })
      }}
    >
      <ExitToApp style={{ color: 'white' }}/>
    </IconButton>
  )
}

export default LogoutButton