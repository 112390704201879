import React from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { Box, Card, CardContent, Icon } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import useGameConfig from '../../hooks/useGameConfig'
import IconButton from '@material-ui/core/IconButton'
import YouTubeIcon from '@material-ui/icons/YouTube'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import FacebookIcon from '@material-ui/icons/Facebook'
import Button from '@material-ui/core/Button'
import useExternalLinkHandler from '../../hooks/useExternalLinkHandler'

function ConfigurableMenuBottom () {
  const { menuConfig: { hamburgerMenu } } = useGameConfig()
  const { showExternalLinkConfirmation } = useExternalLinkHandler()


  return (
    <>
      <List>
        <ListItem key="contact">
          <Button color="primary"
                  variant="contained"
                  fullWidth
                  href={hamburgerMenu.linkToSdgs}
                  onClick={(e) => showExternalLinkConfirmation(e,true)}
                  target="_blank"
                  size="small"
          >
            {hamburgerMenu.menuSdgInfoButtonText}
          </Button>
        </ListItem>
        <ListItem key="gemeinschaftswerk" style={{ textAlign: 'center' }}>
          <Button color="primary"
                  style={{ textAlign: 'center' }}
                  variant="contained"
                  fullWidth
                  href="https://gemeinschaftswerk-nachhaltigkeit.de/"
                  onClick={(e) => showExternalLinkConfirmation(e,true)}
                  target="_blank"
                  size="small"
          >
            Nachhaltikeitsinitiativen entdecken
          </Button>
        </ListItem>
      </List>
      <Card className="hamburger-menu-bottom-text">
        <CardContent className="hamburger-menu-bottom-text-content">
          <Typography
            color="textSecondary"
            gutterBottom
            dangerouslySetInnerHTML={{ __html: hamburgerMenu.menuBottomText }}
          />
          <Box>
            <a
              href={hamburgerMenu.logoUrl}
              onClick={(e) => showExternalLinkConfirmation(e,true)}
              target="_blank"
            >
              <img src={hamburgerMenu.logo.localFile.publicURL} alt="logo"/>
            </a>
          </Box>
          <Box display="flex" justifyContent="space-around" className="social-links-menu">

            {
              hamburgerMenu.menuItemsSocial.map(publicItem => {
                let iconElement = ''

                // Ugly, I know, but the font icons do not have these
                switch (publicItem.menuItemIcon) {
                  case 'facebook':
                    iconElement = <FacebookIcon/>
                    break
                  case 'instagram':
                    iconElement = <InstagramIcon/>
                    break
                  case 'youtube':
                    iconElement = <YouTubeIcon/>
                    break
                  case 'twitter':
                    iconElement = <TwitterIcon/>
                    break
                  default:
                    iconElement = <Icon>{publicItem.menuItemIcon}</Icon>
                }

                return (
                  <IconButton
                    key={publicItem.menuItemUrl}
                    component="a"
                    target="_blank"
                    onClick={(e) => showExternalLinkConfirmation(e,true)}
                    href={publicItem.menuItemUrl}
                  >
                    {iconElement}
                  </IconButton>
                )
              })
            }

          </Box>
        </CardContent>
      </Card>
    </>
  )
}

export default ConfigurableMenuBottom