import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Viewport from "./viewport"

export default function TopLayout({ children }) {
  return (
    <>
      <Viewport />
      <CssBaseline />
      {children}
    </>
  )
}
