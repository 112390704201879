import useIsClient from './useIsClient'
import useGameConfig from './useGameConfig'
import { useEffect } from 'react'

function useExternalLinkHandler () {
  const { isClient } = useIsClient()
  const { texts: { notificationTexts } } = useGameConfig()

  function showExternalLinkConfirmation (e = null, direct = false) {
    if (direct || window && e.target.nodeName === 'A') {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm(notificationTexts.externalLinkConfirmation)) {
        e.preventDefault()
        return false
      }
      if (e.target.href) {
        e.preventDefault()
        window.open(e.target.href, '_blank')
      }
    }
  }

  useEffect(() => {
    window.showExternalLinkConfirmation = showExternalLinkConfirmation

  }, [])

  function addExternalLinkConfirmation (content) {
    if (!isClient) return null

    // todo: add confirmation
    return content.replaceAll('href=', 'target="_blank" onclick="(e) => showExternalLinkConfirmation(e)" href=')
  }

  return { addExternalLinkConfirmation, showExternalLinkConfirmation }
}

export default useExternalLinkHandler