import React, { useState } from 'react'
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import useTeamInviteNotifier from '../hooks/useTeamInviteNotifier'
import List from '@material-ui/core/List'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import Divider from '@material-ui/core/Divider'
import DoneIcon from '@material-ui/icons/Done'
import CancelIcon from '@material-ui/icons/Cancel'
import Alert from '@material-ui/lab/Alert'
import useTeam from '../hooks/useTeam'
import useGameConfig from '../hooks/useGameConfig'
import useUser from "../hooks/useUser";

function NotificationModal ({ open, setOpen }) {
  const { removeTeamInvite } = useUser()
  const { notifications, removeNotificationBySlug } = useTeamInviteNotifier()
  const { join, declineInvitation } = useTeam()
  const [declineLoading, setDeclineLoading] = useState(false)
  const [acceptLoading, setAcceptLoading] = useState(false)
  const { texts: { notificationTexts } } = useGameConfig()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
        className="notification-dialog"
      >
        <DialogTitle>{notificationTexts.yourNotifications}</DialogTitle>
        <DialogContent>
          <List>
            {notifications.length === 0 ?
              <Alert severity="info" className="info-alert">
                {notificationTexts.youHaveNoNotifications}
              </Alert>
              :
              notifications?.map(notification => {
                if (notification.action === 'added-to-group') {
                  return (
                    <>
                      {declineLoading || acceptLoading ? <LinearProgress/>
                        :
                        <ListItem key={notification.groupDetails.slug}>
                          <ListItemIcon>
                            <PersonAddIcon/>
                          </ListItemIcon>
                          <Grid container>
                            <Grid item xs={12}>
                              <ListItemText secondary={notificationTexts.youAreInvitedToTheGroup
                                .replace('${inviter}', notification.groupDetails.inviter)
                                .replace('${groupName}', notification.groupDetails.name)
                              }/>
                            </Grid>
                            <Grid item xs={12}>
                              <Box display="flex" justifyContent="space-between" className="space-top-20">
                                <Button
                                  //variant="outlined"
                                  size="small"
                                  color="primary"
                                  startIcon={<DoneIcon/>}
                                  disabled={acceptLoading}
                                  onClick={() => {
                                    setAcceptLoading(true)
                                    removeTeamInvite(notification.groupDetails)
                                    join(notification.groupDetails).then(() => {
                                      setAcceptLoading(false)
                                    })
                                    removeNotificationBySlug(notification.groupDetails.slug)
                                  }}>
                                  Beitreten
                                </Button>
                                <Button
                                  //variant="outlined"
                                  size="small"
                                  startIcon={<CancelIcon/>}
                                  disabled={declineLoading}
                                  onClick={() => {
                                    setDeclineLoading(true)
                                    declineInvitation(notification.groupDetails)
                                      .then(r => {
                                        setDeclineLoading(false)
                                      }).catch(e => setDeclineLoading(false))
                                    removeNotificationBySlug(notification.groupDetails.slug)
                                  }}>
                                  Ablehnen
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </ListItem>
                      }
                      <Divider key={notification.groupDetails.slug + 'divider'} variant="fullWidth" component="li"/>
                    </>
                  )
                }
              })
            }
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {notificationTexts.close}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default NotificationModal