import React, { useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Status from './app/Status'
import Logo from '../../static/images/logo.inline.svg'
import LogoNoText from '../../static/images/logo_no_text.inline.svg'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery'
import Menu from './menu/Menu'
import Link from '@material-ui/core/Link'
import useUser from '../hooks/useUser'

const ButtonAppBar = ({ pageName, pageSubName }) => {
  const [state, setState] = useState({ top: false })
  const theme = useTheme()
  const screen_sm_up = useMediaQuery(theme.breakpoints.up('lg'))
  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }
  const { signedIn } = useUser()

  return (
    <div className="app-bar">
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer('left', true)}
          >
            <MenuIcon/>
          </IconButton>
          <div>
            <Link href={signedIn ? '/app/overview' : '/'}>
              {
                screen_sm_up ? <Logo className="logo"/> : <LogoNoText className="logo-no-text"/>
              }
            </Link>
          </div>
          <div style={{ flexGrow: 1 }}>
            <Typography variant="h6" className="app-bar-title">{pageName}</Typography>
          </div>

          <Status className="app-bar-status"/>
        </Toolbar>
      </AppBar>
      <Menu toggleDrawer={toggleDrawer} state={state}/>
    </div>
  )
}

export default ButtonAppBar
